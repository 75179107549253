/****************************************************************
 * Theme Fixes
 ****************************************************************/

/****************************************************************
 * Ripples
 ****************************************************************/
/** Corrects issue with ripple surface color on buttons */
body .mdc-button .mdc-button__ripple:before,
body .mdc-button .mdc-button__ripple:after {
  background-color: currentColor;
}

/** Corrects issue with ripple surface color on fabs */
body .mdc-fab .mdc-fab__ripple:before,
body .mdc-fab .mdc-fab__ripple:after {
  background-color: currentColor;
}

/****************************************************************
 * Top App Bar
 ****************************************************************/
body .mdc-top-app-bar {
  color: var(--mdc-theme-on-primary, white);
}

/****************************************************************
 * Tabs
 ****************************************************************/

/** In top app bar */
.mdc-top-app-bar .mdc-tab-bar {
  --mdc-theme-primary: var(--mdc-theme-on-primary);
  --mdc-theme-on-surface: var(--mdc-theme-on-primary);
  color: var(--mdc-theme-on-primary);
}

/** Fixes colors for both top app bar and other situations where the text color might be different */
.mdc-tab,
body .mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
  color: var(--mdc-theme-on-surface);
}

.mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
  opacity: 0.6;
}

/****************************************************************
 * List Items
 ****************************************************************/
/** Fixes the focus state color */
body .mdc-drawer .mdc-list-item--activated {
  color: var(--mdc-theme-primary);
}

/** Fixes list item color in drawers */
body .mdc-drawer .mdc-list-item:not(.mdc-list-item--activated) {
  color: var(--mdc-theme-on-surface);
}

/** Fixes an issue where dividers on cards had bullet points */
body .mdc-list-divider {
  list-style: none;
}

/****************************************************************
 * Select
 ****************************************************************/
/** Correct color of focused label */
body
  .mdc-select:not(.mdc-select--disabled):not(.mdc-select--invalid).mdc-select--focused
  .mdc-floating-label {
  color: var(--mdc-theme-primary);
}

/****************************************************************
 * Textfield
 ****************************************************************/
/** Correct color of focused label */
body
  .mdc-text-field:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label {
  color: var(--mdc-theme-primary);
}

/****************************************************************
 * Chips
 ****************************************************************/
/** Corrects focus outline */
body .mdc-chip__text__primary-action:focus {
  outline: none;
}

/****************************************************************
 * Linear Progress
 ****************************************************************/
/**
  * Corrects an issue where anything other than text-align left breaks layout
  * https://github.com/jamesmfriedman/rmwc/issues/590
  */
.mdc-linear-progress {
  text-align: left;
}
